import * as styles from './NavigationItem.module.scss'
import cn from 'classnames'

import React, { useState } from 'react'
import { useDelayUnmount } from 'utils'

import { ActionButton } from 'ui'

const NavigationItem = ({ type, disabled, onClick }) => {
  const [timeoutId, setTimeoutId] = useState(null)
  const [hovered, setHovered] = useState(false)
  const shouldRenderButton = useDelayUnmount(hovered, 100)

  const handleMouseEnter = delay => () => {
    if (timeoutId) return
    const timeout = setTimeout(() => setHovered(true), delay)
    setTimeoutId(timeout)
  }

  const handleMouseLeave = () => {
    clearTimeout(timeoutId)
    setTimeoutId(null)
    setHovered(false)
  }

  return (
    <div
      onClick={() => {
        handleMouseEnter(0)
        onClick()
      }}
      className={cn(styles.container, styles[type], {
        [styles.disabled]: disabled,
      })}
      onMouseEnter={handleMouseEnter(300)}
      onMouseLeave={handleMouseLeave}>
      {shouldRenderButton && (
        <ActionButton
          title={`Go ${type}!`}
          isMounted={hovered}
          onClick={onClick}
        />
      )}
    </div>
  )
}

export default NavigationItem
