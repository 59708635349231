import React from 'react'
import * as styles from './AuthorInfo.module.scss'

import { ImageFromPath } from 'utils'

const AuthorInfo = ({ url, name, position }) => {
  return (
    <div className={styles.author}>
      <ImageFromPath url={url} />
      <div>
        <p className={styles.author__name}>{name}</p>
        <p className={styles.author__position}>{position}</p>
      </div>
    </div>
  )
}

export default AuthorInfo
