import * as styles from './ClientsSwiper.module.scss'
import cn from 'classnames'

import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react'

import SwiperCore, { Navigation } from 'swiper'
import { Swiper as ReactSwiper, SwiperSlide } from 'swiper/react'

import { Title, SubTitle } from 'ui'
import Progress from './Progress'
import AuthorInfo from './AuthorInfo'
import NavigationItem from './NavigationItem'

import sliders_settings from 'site/sliders/settings.json'
import review_slider from 'site/sliders/review_slider.json'

import 'swiper/css'

SwiperCore.use([Navigation])

const ClientsSwiper = ({ title, subTitle, progressClass }) => {
  const [autoSlideTimeoutId, setAutoSlideTimeoutId] = useState(null)
  const [
    disableNavigationButtonsTimeoutId,
    setDisableNavigationButtonsTimeoutId,
  ] = useState(null)
  const [disableNavigationButtons, setDisableNavigationButtons] = useState(
    false,
  )
  const [activeSlideIndex, setActiveSlideIndex] = useState(0)

  const speed = sliders_settings.review_speed || 500
  const autoSlideDelay = sliders_settings.review_autoslide_delay || 40000

  const swiperSettings = useMemo(
    () => ({
      initialSlide: 0,
      slidesPerView: 1,
      slidesPerGroup: 1,
      centeredSlides: true,
      grabCursor: true,
      loop: true,
      speed,
    }),
    [speed],
  )

  const sliderRef = useRef(null)

  const handlePrev = useCallback(() => {
    if (!sliderRef.current?.swiper) return
    sliderRef.current.swiper.slidePrev()
  }, [])

  const handleNext = useCallback(() => {
    if (!sliderRef.current?.swiper) return
    sliderRef.current.swiper.slideNext()
  }, [])

  const autoSlideAfterDelay = useCallback(() => {
    const timeout = setTimeout(() => handleNext(), autoSlideDelay)
    setAutoSlideTimeoutId(timeout)
  }, [])

  const onSlideChange = useCallback(
    ({ realIndex }) => {
      clearTimeout(autoSlideTimeoutId)
      setAutoSlideTimeoutId(null)
      autoSlideAfterDelay()

      setDisableNavigationButtons(true)
      const timeout = setTimeout(
        () => setDisableNavigationButtons(false),
        speed,
      )
      setDisableNavigationButtonsTimeoutId(timeout)

      setActiveSlideIndex(realIndex)
    },
    [autoSlideTimeoutId],
  )

  useEffect(() => {
    const timeout = setTimeout(() => {
      clearTimeout(disableNavigationButtonsTimeoutId)
      setDisableNavigationButtonsTimeoutId(null)
    }, speed)
    return () => clearTimeout(timeout)
  }, [disableNavigationButtonsTimeoutId])

  useEffect(() => {
    autoSlideAfterDelay()
    return () => clearTimeout(autoSlideTimeoutId)
  }, [])

  return (
    <div className="row">
      <div className="col-xxl-8 col-lg-8 col-md-12">
        <Title text={title} />
        {subTitle && <SubTitle text={subTitle} />}
      </div>
      <div className={cn('col-12', styles.wrapper)}>
        <ReactSwiper
          className={styles.swiper}
          onSlideChange={onSlideChange}
          {...swiperSettings}
          ref={sliderRef}>
          {review_slider.review_slider?.map((s, index) => (
            <SwiperSlide
              className={styles.swiper__slide}
              key={`review_slider-item-${index}`}>
              <NavigationItem
                type="prev"
                onClick={handlePrev}
                disabled={disableNavigationButtons}
              />
              <NavigationItem
                type="next"
                onClick={handleNext}
                disabled={disableNavigationButtons}
              />
              <Title
                variant="small"
                text={s.description}
                as="q"
                className={styles.swiper__slide__title}
              />
              <AuthorInfo
                url={s.image}
                name={s.author}
                position={s.author_position}
              />
            </SwiperSlide>
          ))}
        </ReactSwiper>
      </div>
      <div className="col-12 position-relative">
        <Progress
          className={progressClass}
          min={1}
          max={review_slider.review_slider.length}
          value={activeSlideIndex + 1}
        />
      </div>
    </div>
  )
}
export default ClientsSwiper
