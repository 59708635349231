import cn from 'classnames'

import React, { useRef } from 'react'

import { useObserver } from 'utils'

import { isMobile } from 'react-device-detect'

const SectionMob = ({ children, className, containerClassName, ...props }) => {
  return (
    <section className={className} {...props}>
      <div className={cn('container', containerClassName)}>{children}</div>
    </section>
  )
}

const SectionWeb = ({ children, className, containerClassName, ...props }) => {
  const containerRef = useRef(null)
  const [isIntersecting, isScrolledFromTop] = useObserver(containerRef)

  const scrollClass = !isIntersecting
    ? isScrolledFromTop
      ? 'scrollTopBg'
      : 'scrollBotBg'
    : ''

  return (
    <section
      className={cn(className, !!containerRef?.current && scrollClass)}
      {...props}>
      <div className={cn('container', containerClassName)} ref={containerRef}>
        {children}
      </div>
    </section>
  )
}

const SectionWithScrollBg = ({ children, ...props }) => {
  return isMobile ? (
    <SectionMob {...props}>{children}</SectionMob>
  ) : (
    <SectionWeb {...props}>{children}</SectionWeb>
  )
}

export default SectionWithScrollBg
