import React from 'react'
import * as styles from './Progress.module.scss'
import cn from 'classnames'

const Progress = ({ min, max, value, className }) => {
  return (
    <div className={cn(styles.counter, className)}>
      <span>{min + value - 1}</span>
      <div className={styles.counter__progress}>
        <div
          className={styles.counter__progress__value}
          style={{ width: `${(value / max) * 100}%` }}></div>
      </div>
      <span>{max}</span>
    </div>
  )
}
export default Progress
